<template>
    <div class="contents allVod">
        <h1 style="height: 50px;line-height: 50px;margin-top:50px;">파노라마 뷰어</h1>
        <div class="content-box">
            <section class="panorama-main">
                <View360  :projection="projection" :autoplay="true"  style="width: 100%; height: 600px; margin-bottom:30px;" />
                <img class="original" :src="originImage" v-if="false"/>
                <input type="file" id="upload-image" accept="image/*" @change="getFileName" />
            </section>
        </div>
    </div>
</template>


<script>
import { View360, EquirectProjection } from "@egjs/vue-view360";
import "@egjs/vue-view360/css/view360.min.css";
export default {
    data() {
        return {
            projection: null,
            originImage: null
        }
    },
    created() {
        console.log(typeof(require("../../../assets/dummyimg.jpg") ))
        this.projection = new EquirectProjection({
            src: require("../../../assets/dummyimg.jpg") 
        })

        // this.projection = new EquirectProjection({
        //     src: ""
        // })
        // this.projection = new EquirectProjection({
        //     src: ""
        // });
    },
    components: {
        View360, 
    },
    methods: {
        
        async getFileName(e) {
            const imageData = e.target.files[0]
            this.originImage = await this.base64(imageData)
            this.projection = new EquirectProjection({
                src: this.originImage
            });
        },

        base64(file) {
            // 비동기적으로 동작하기 위하여 promise를 return 해준다.
            return new Promise(resolve => {
                // 업로드된 파일을 읽기 위한 FileReader() 객체 생성
                let a = new FileReader()
                // 읽기 동작이 성공적으로 완료됐을 때 발생
                a.onload = e => {
                    resolve(e.target.result)
                    // 썸네일을 보여주고자 하는 <img>에 id값을 가져와 src에 결과값을 넣어준다.
                    // const previewImage = document.getElementById('preview')
                    // previewImage.src = e.target.result
                }
                // file 데이터를 base64로 인코딩한 문자열. 이 문자열을 브라우저가 인식하여 원래 데이터로 만들어준다.
                a.readAsDataURL(file)
            })
        }
    }
}
</script>

<style lang="scss">
.title {
    display: none;
}
.is-16by9 {
    width: 1000px;

}
.content-box {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
}
.panorama-content {
    width: 100%;
    height: 100%;
}

.panorama-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    margin-top: 50px;
}

.panorama-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .original {
        width: 300px;
        height: 100px;
        object-fit: contain;
        position: absolute;
        bottom: 50px;
        right: -10px;
    }
}

</style>